.accordion-footer {
  .accordion-item {
    border: 0;

    .accordion-button {
      padding: 0 0 0 1.25rem;
      line-height: 2;
      background: transparent;
      box-shadow: none !important;

      .fal {
        position: absolute;
        transition: .15s transform ease-in-out;
        transform: rotate(90deg);
        left: 0;
        top: 12px;
      }

      &.collapsed {
        .fal {
          transform: rotate(0deg);
        }
      }

      &:hover {
        color: $primary;
      }

      &:after {
        display: none;
      }
    }

    .accordion-body {
      padding: 0;

      ul {
        list-style: circle;
      }
    }
  }
}
