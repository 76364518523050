.header {
  &.header--center-light,
  &.header--sidebar {
    background-color: $bg-switch;
  }

  &.header--background {
    .cover {
      @extend .vh-75, .vh-lg-100;
      overflow: hidden;
      position: relative;
    }

    &.header--halfscreen {
      .cover {
        @extend .vh-lg-75;
      }
    }
  }

  &.header--center {
    margin-bottom: 2rem;
    @extend .text-lg-center;
  }

  &.header--center:not(.header--center-media) {
    @extend .py-2, .py-md-3, .py-lg-5;
  }
}

.navbar-inverse ~ {
  .header--fullscreen,
  .header--halfscreen {
    margin-right: 0;
    margin-left: 0;
    border-radius: 0 !important;
  }
}

.navbar-light ~ {
  .header--fullscreen .cover {
    min-height: calc(100vh - $nav-height-xl - 2rem) !important;
  }

  .header--halfscreen .cover {
    min-height: calc(75vh - $nav-height-xl - 2rem) !important;
  }
}

@include media-breakpoint-up(md) {
  .header {
    &.header--center {
      margin-bottom: 4rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .header {
    margin-left: $container-padding-x;
    margin-right: $container-padding-x;

    &.header--center {
      margin-bottom: 0;
    }
  }
}
