.list-group {
  list-style: decimal;
  padding-left: 1.25rem;
}

.list-taxonomie {
  display: flex;
  list-style: unset;
  padding: 0;
  margin-bottom: 1rem;
  @extend .scroll-x;

  .badge {
    color: $white;
    margin-right: .25rem;
  }
}

.list-group-scrollspy {
  list-style: unset;
  margin-top: -0.5rem;

  .list-group-item {
    display: list-item;
    border-bottom: 1px solid $border-color;
    padding: .5rem 0;
    line-height: 1.4;
    white-space: nowrap;
    //overflow: hidden;
    text-overflow: ellipsis;
    background-color: transparent;

    &.list-group-item-action:focus,
    &.list-group-item-action:hover {
      background-color: transparent;
    }

    &.active {
      z-index: 2;
      color: $primary;
      font-weight: 600;
      background-color: transparent;
      border-bottom: 1px solid $border-color;
      transition: 0s color;
    }

    &:last-of-type {
      border: none !important;
      padding-bottom: 0;
    }

    &:hover {
      color: $gray-600;
      background-color: $white;
    }
  }
}

.list-timeline {
  > .row:last-of-type {
    .line-v {
      &:after {
        //background: linear-gradient(0deg, rgba($primary,0) 0%, rgba($primary,1) 100%);
      }
    }
  }

  .user {
    position: relative;

    .img-small {
      background-color: $white;
      width: 100%;
      box-shadow: $box-shadow;
    }
  }
}

.line-v {
  &:after {
    content: "";
    width: 1px;
    background-color: $primary;
    margin: auto;
    position: absolute;
    height: calc(100% + -2.8rem);
    top: 4rem;
    left: 1.45rem;
  }
}

.list-spots {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;

  .spot-item {
    position: absolute;

    &.spot-item-sm {
      &:before {
        width: 30px;
        height: 30px;
        left: -15px;
        right: 0;
        top: -15px;
        animation-delay: .25s;
      }

      .spot-link {
        padding: .25rem .75rem;
        background-color: $secondary;
        color: $white;
        font-size: 1rem;
        bottom: 25px;

        &:after {
          border-width: 6px 6px 0 6px;
          bottom: -6px;
          border-color: $secondary transparent transparent transparent;
        }
      }
    }

    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      animation: 2s pulse ease-in-out infinite;
      content: "";
      width: 40px;
      height: 40px;
      background-color: $white;
      border-radius: 100%;
      left: -20px;
      right: 0;
      top: -20px;
      z-index: 1;

      @include media-breakpoint-down(xxl) {
        top: -16px;
      }
    }

    &.spot-1 {
      left: 18%;
      top: 40.35%;
    }

    &.spot-2 {
      left: 37.8%;
      top: 12.05%;
    }

    &.spot-3 {
      right: 25.85%;
      bottom: 42.4%;
    }

    &.spot-4 {
      left: 15.6%;
      top: 13.1%;
    }

    &.spot-5 {
      left: 22.56%;
      top: 20.56%;
    }

    &.spot-6 {
      right: 60.05%;
      bottom: 51.6%;
    }

    &.spot-7 {
      left: 29.45%;
      top: 30.95%;
    }

    &.spot-8 {
      left: 62.48%;
      top: 83.6%;
    }

    &.spot-9 {
      right: 17.35%;
      bottom: 27.8%;
    }
  }

  span.spot-link {
    transition: none;

    &:hover {
      bottom: 35px;
    }
  }

  .spot-link {
    padding: .5rem 1rem;
    background-color: $white;
    box-shadow: $box-shadow-lg;
    font-weight: 600;
    font-size: 1.25rem;
    position: absolute;
    transition: .25s all ease-in-out;
    bottom: 35px;
    transform: translate(-50%, 0px);
    text-align: center;
    line-height: 1.4;
    white-space: nowrap;
    border-radius: $border-radius;

    &:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: $white transparent transparent transparent;
      position: absolute;
      content: "";
      bottom: -10px;
      left: 0;
      right: 0;
      margin: auto;
    }

    &:hover {
      bottom: 30px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .list-timeline {
    position: relative;

    li {
      .user:after {
        content: "";
        left: -3rem;
        right: 0;
        top: 140px;
        bottom: 0;
        width: 1px;
        height: calc(100% - 60px);
        background-color: $primary;
        margin-left: auto;
        margin-right: auto;
        display: block;
        position: absolute;
        padding-top: 2px;
      }

      &:last-of-type {
        .user:after {
          height: 50%;
          background: $primary;
          background: linear-gradient(0deg, rgba($primary,0) 0%, rgba($primary,1) 100%);
        }
      }
    }
  }

  .list-timeline {
    li {
      .user {
        &:before {
          content: "\f04b";
          font-family: "Font Awesome 6 Pro";
          font-weight: 200;
          left: -3rem;
          right: 0;
          top: 94px;
          bottom: 0;
          width: 32px;
          height: 32px;
          background-color: $primary;
          margin-left: auto;
          margin-right: auto;
          display: flex;
          position: absolute;
          border-radius: 5rem;
          z-index: 1;
          align-items: center;
          justify-content: center;
          color: #fff;
          padding-top: 2px;
        }

        &.image:before {
          content: "\f03e";
        }

        &.video:before {
          content: "\f04b";
        }

        &.text:before {
          content: "\f893";
        }

        &.job:before {
          content: "\f007";
        }

        &.event:before {
          content: "\f133";
        }
      }
    }
  }
}
