body.rounded {
  .rounded-switch {
    border-radius: calc($border-radius-theme / 2);
    overflow: hidden;
  }

  .rounded-switch-lg {
    border-radius: calc($border-radius-theme / 2);
    overflow: hidden;
  }

  .card {
    @extend .rounded-switch-lg;
  }

  .card {
    .col-4,
    .col-lg-6 {
      .card-image img {
        @extend .rounded-switch-lg;
      }
    }
  }

  .card--sm-h,
  .card--sm-h .card-image {
    @extend .rounded-switch;
  }

  .card--md-v .card-image img {
    border-radius: 0 !important;
  }

  .sidebar {
    .card {
      @extend .rounded-switch;
    }
  }

  &.template-default {
    .sidebar {
      .card {
        border-radius: 0 !important;
      }
    }
  }

  .btn,
  .nav-pills .nav-link,
  .form-control,
  .input-group-text {
    border-radius: $border-radius-theme * .5;
  }

  .img-fluid:not(svg) {
    @extend .rounded-switch;
  }

  .alert,
  .alert .border-md {
    @extend .rounded-switch;
  }

  .bordered:after {
    display: none;
  }

  .modal-content {
    @extend .rounded-switch;
  }

  .header {
    img, video {
      border-radius: 0 !important;
    }

    .cover {
      border-radius: 0;
    }
  }

  .accordion-item:first-of-type {
    border-top-left-radius: $border-radius-theme;
    border-top-right-radius: $border-radius-theme;
    overflow: hidden;
  }

  .accordion-item:last-of-type {
    border-bottom-left-radius: $border-radius-theme;
    border-bottom-right-radius: $border-radius-theme;
  }

  .badge {
    border-radius: $border-radius-theme * 3;
  }
}

@include media-breakpoint-down(md) {

}

@include media-breakpoint-down(xl) {
  body.rounded {
    .modal-fullscreen .modal-content {
      border-radius: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  body.rounded {
    .rounded-switch {
      border-radius: $border-radius-theme;
    }

    .rounded-switch-lg {
      border-radius: $border-radius-theme * 2;
    }
  }
}

@include media-breakpoint-up(xl) {
  body.rounded {
    .header {
      &.header--center,
      &.header--collage,
      &.header--sidebar,
      &.header--background,
      &.header--sidebar {
        border-radius: $border-radius-theme;
        overflow: hidden;
      }

      &.header--center {
        .cover {
          border-radius: $border-radius-theme;
        }
      }

      &.header--media-text img {
        border-radius: $border-radius-theme $border-radius-theme 0 $border-radius-theme !important;
      }
    }
  }
}
