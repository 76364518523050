.navbar {
  transition: .2s box-shadow ease-in-out;
  background: transparent;
  box-shadow: none;
  font-family: $font-family-sans-serif;
  font-size: $font-size-lg;
  left: 0;
  right: 0;
  z-index: 8000;
  @extend .py-xl-0, .px-xl-2, .navbar-expand-xl;

  > .row {
    flex-shrink: 0;
    flex-grow: 1;
  }

  &.navbar-light-simple {
    padding: 1rem 0;
  }

  a {
    font-weight: 500;
  }

  i {
    transition: .15s color ease-in-out;
  }

  .fa-light {
    color: $body-color;
  }

  .navbar-nav {
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .btn-outline {
    border-color: $border-color;
    color: $body-color;

    &:hover {
      background-color: $primary;
      border-color: $primary;
      color: $white;

      i {
        color: $white;
      }
    }
  }

  &.navbar-dark {
    #btn-toggle-nav i {
      color: $white;
    }
  }

  &.navbar-inverse {
    .navbar-brand-color {
      fill: $white;
      color: $white;
    }

    .btn-outline {
      border-color: $white;
      color: $white;

      &:hover {
        background-color: $white;
        color: $primary;

        i {
          color: $primary;
        }
      }
    }

    .fa-light {
      color: $white;
    }

    ~ .modal-backdrop-menu {
      background-color: $dark;
    }
  }

  .dropdown-menu li > a {
    font-size: $font-size-base;
  }

  .logo-light {
    display: none;
  }

  .logo-dark {
    display: inline-block;
  }

  .nav-link {
    cursor: pointer;
    transition: .2s padding-top ease-in-out, .2s padding-bottom ease-in-out, .15s color ease-in-out, .15s opacity ease-in-out;
    display: flex;
    align-items: center;

    &.active {
      color: $secondary;
    }
  }

  .fa-search,
  .btn:not(:hover) {
    transition: none !important;
  }
}

.navbar-brand {
  position: relative;
  z-index: 50;

  img,
  svg {
    max-width: 45px;
    min-width: 45px;
    width: 100%;
    height: auto;
  }

  .navbar-brand-color {
    fill: $primary;
    color: $primary;
  }
}

body:not(.sticky) .navbar-inverse {
  .nav-link {
    color: rgba(255,255,255,.75);

    &:hover {
      color: rgba(255,255,255,1);
    }
  }

  #btn-toggle-nav i {
    color: $white;
  }
}

.navbar-left {
  font-family: $font-family-sans-serif;
  font-size: $font-size-lg;

  a {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.nav-pills-sm {
  li {
    margin-right: .5rem;
    margin-bottom: .5rem;
  }

  .nav-link {
    font-size: $font-size-sm;
    border: 1px solid $border-color;
    margin: 0;
    padding: 0.5rem 1rem;

    &.active {
      border-color: $primary;
    }
  }
}

.nav-pills-half {
  li {
    min-width: calc(50% - 0.5rem);
  }
}

@include media-breakpoint-up(xl) {
  .navigation {
    .nav-item {
      .nav-link:hover {
        .dropdown-menu {
          opacity: 1;
          z-index: 1;
        }
      }
    }
  }
}
