::selection {
  background-color: $primary;
  color: $white;
}

*:focus-visible {
  outline: none;
}

#__bs_notify__ {
  display: none !important;
}

body {
  padding: 0 !important;
}

a {
  transition: .15s color ease-in-out;
}

.neos-backend {
  .link-before:before {
    display: none;
  }
}

.footer {
  a:not(.btn) {
    color: $dark;

    &:hover {
      color: $primary;
    }
  }

  a.text-muted {
    &:hover {
      color: $primary !important;
    }
  }
}

figure {
  margin: 0;

  figcaption {
    font-size: $font-size-sm;
    color: $text-muted;
    margin-top: .5rem;
  }

  picture {
    img {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='L9' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' style='enable-background:new 0 0 100 100;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2397a3af;%7D%0A%3C/style%3E%3Cpath class='st0' d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'%3E%3CanimateTransform accumulate='none' additive='replace' attributeName='transform' attributeType='XML' calcMode='linear' dur='1s' fill='remove' from='0 50 50' repeatCount='indefinite' restart='always' to='360 50 50' type='rotate'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: 65px;
      background-position: center;
    }
  }
}

.loader-0 {
  img {
    background-image: none !important;
  }
}

.figure {
  display: block;
}

hr {
  background-color: $gray-400;
}

.img-brand {
  max-width: 220px;
  width: auto;
  padding: 2rem;
  max-height: 120px;
  background-color: $gray-100;
}

.img-bg-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: $secondary;
}

.img-border-center {
  border: 1px solid $gray-100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: $white;
}

.img-svg {
  background-color: $gray-200;
}

.cover .img-svg {
  object-fit: contain;
}

.breadcrumb-item {
  @extend .text-ellipsis;
  max-width: 320px;
}

.layout-blank {
  .navbar {
    opacity: 0;
    z-index: -5 !important;

    &.navbar-scroll {
      opacity: 1;
      z-index: 8000 !important;
    }
  }

  #scrollTopBtn {
    display: none !important;
  }
}

.meta {
  .badge:not(:last-of-type) {
    margin-right: .5rem;
  }
}

.palette-color {
  width: 28px;
  height: 22px;
  border-radius: $border-radius;
  display: inline-block;
  margin: 0.65rem 0.15rem 0;
}

.media-backdrop {
  content: "";
  background-color: rgba($dark, .5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

.table-separated {
  tr {
    td:first-of-type {
      border-right: 30px solid $gray-100;
    }
  }
}

table {
  td {
    i {
      width: 28px;
      min-width: 28px;
      line-height: 1.8 !important;
      align-items: center;
      justify-content: center;
      display: flex !important;
    }
  }
}

.table-td-flex {
  td {
    display: flex;
  }
}

.table-dark {
  color: $white;
}

.container-xxl {
  max-width: 1650px;
}

.modal {
  z-index: 9000;
}

.bordered {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    height: 100%;
    background-color: $gray-300;
    transition: .15s all ease-in-out;
  }

  &:hover {
    &:after {
      background-color: $primary !important;
    }
  }
}

.scroll-x {
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  &:before {
    content: "";
    background: rgb(255,255,255);
    background: linear-gradient(270deg, rgba($bg-switch,1) 0%, rgba($bg-switch,0) 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 50px;
    opacity: 1;
  }
}

.scroll-y {
  position: relative;

  .scroll-content,
  > ul {
    height: 90px;
    margin-bottom: .5rem;
    padding-left: 1.75rem;
    position: relative;
    overflow-y: scroll;
    padding-right: .5rem;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 20px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $gray-200;
      border-radius: 20px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $gray-400;
      border-radius: 20px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $gray-500;
    }
  }

  &:before {
    content: "";
    background: linear-gradient(0deg, $gray-100 0%, transparent 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50px;
    opacity: .95;
  }
}

.tab-content .border {
  p:last-of-type {
    margin-bottom: 0;
  }
}

.bg-primary {
  h1, h2 {
    color: $white;
  }

  .table td {
    border-color: rgba($white, .1) !important;
    color: $gray-100;

    .text-primary {
      color: rgba($white, .5) !important;
    }
  }
}

@include media-breakpoint-up(sm) {
  .scroll-y {
    .scroll-content,
    > ul {
      height: 115px;
    }
  }

  .line-v {
    &:after {
      left: 2.25rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .scroll-y {
    .scroll-content,
    > ul {
      height: 175px;
    }
  }

  .line-v {
    &:after {
      left: 2.7rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .line-v {
    &:after {
      right: 0;
      height: calc(100% + 3rem);
      background-color: $primary;
      top: -1rem;
      left: 0;
    }

    &.line-v-sm {
      &:after {
        height: calc(100% + 2rem);
      }
    }
  }

  .scroll-y {
    .scroll-content,
    > ul {
      height: 165px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .sticky-xl-top {
    top: 2rem;
  }

  .scroll-y {
    .scroll-content,
    > ul {
      height: 115px;
    }
  }

  .min-height-header {
    min-height: 400px;
  }
}

@include media-breakpoint-up(xxl) {
  .scroll-y {
    .scroll-content,
    > ul {
      height: 150px;
    }
  }
}
